import Vue from "vue";
import RealVerifiedExtend from "./RealVerifiedExtend";

const RealConstructor = Vue.extend(RealVerifiedExtend);

const Real = () => {
  const options = {
    data() {
      return {
        show: true,
      };
    },
    mounted() {},
  };

  const instance = new RealConstructor(options);
  instance.$mount();
  // instance.vm = instance.$mount();
  // instance.vm.show = true;
  document.body.appendChild(instance.$el);
};

export default function install() {
  Vue.prototype.$realVerified = Real;
}
