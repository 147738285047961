// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RealVerifiedExtend_form_W3xO7{padding:.26667rem 0 .53333rem 0}.RealVerifiedExtend_form_W3xO7 .van-cell{padding:.16rem .8rem .16rem .8rem;background-color:#212121}.RealVerifiedExtend_form_W3xO7 .van-cell .van-field__label{font-size:.37333rem;font-weight:400;color:#fff;text-shadow:0 .08rem .21333rem rgba(0,0,0,.5)}.RealVerifiedExtend_form_W3xO7 .van-cell .van-field__control{box-shadow:0 .08rem .21333rem 0 rgba(0,0,0,.2);border-radius:.34667rem;border:.02667rem solid #454545;color:#fff;padding-left:.26667rem;font-size:.37333rem;height:.8rem}.RealVerifiedExtend_form_W3xO7 .van-cell .van-field__button{padding-left:.10667rem}.RealVerifiedExtend_form_W3xO7 .van-cell .van-field__button .van-button{height:.8rem;line-height:.8rem;background:#323232;box-shadow:0 .08rem .21333rem 0 rgba(0,0,0,.4),0 .05333rem .16rem 0 rgba(28,28,28,.3);border-radius:.32rem}.RealVerifiedExtend_form_W3xO7 .van-cell:after{display:none}.RealVerifiedExtend_code_1Y9xI{width:1.6rem!important;height:.53333rem!important}", ""]);
// Exports
exports.locals = {
	"form": "RealVerifiedExtend_form_W3xO7",
	"code": "RealVerifiedExtend_code_1Y9xI"
};
module.exports = exports;
