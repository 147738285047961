import Vue from "vue";
import axios from "axios";
// import QS from "qs";
import config from "../config";
import { Toast } from "vant";
import router from "../router";
import { getStorage } from "../utils/util";

axios.defaults.baseURL = config.BASE_URL;
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

// axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

axios.interceptors.request.use(
  (config) => {
    const token = getStorage("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
axios.interceptors.response.use(
  (response) => {
    // http状态码为200
    if (response.status === 200) {
      const res = response.data; // 接口返回的数据，包括code，msg，data
      if (res) {
        // 请求成功
        if (res.code === 200) {
          return Promise.resolve(res.data);
        }
        if (res.code === 10888) {
          // 需要实名认证
          Vue.prototype.$realVerified();
          return Promise.reject(res);
        }
        Toast.fail(res.msg);
        return Promise.reject(res);
      } else {
        return Promise.reject();
      }
    }
  },
  (err) => {
    Toast.fail(err.response.data.msg);
    // 此处401是http的返回码
    if (err.response.status === 401) {
      // 清楚过期的token以及跳到登录页面
      localStorage.clear();
      router.replace({ name: "Login" });
    }
    return Promise.reject(err);
  }
);

export const get = (url, data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: data,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

export const post = (url, data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url,
      // data: QS.stringify(data),
      data: data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
