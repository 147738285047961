// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Code_codeBtn_3coFQ{width:3.57333rem;background:#363636;box-shadow:0 .26667rem .8rem 0 rgba(0,0,0,.5)}.Code_small_2b2aO{width:2.24rem;font-size:.32rem;padding:0 .26667rem}", ""]);
// Exports
exports.locals = {
	"codeBtn": "Code_codeBtn_3coFQ",
	"small": "Code_small_2b2aO"
};
module.exports = exports;
