import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/vant";

import "normalize.css";
import "vant/lib/index.css";
import "amfe-flexible/index";
import "./assets/css/common.scss";

// 公共组件
import RealVerifiedExtend from "@/components/RealVerifiedExtend/index.js";
Vue.use(RealVerifiedExtend);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
