<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getUserInfo } from "@/api";
import { setStorage, isInWX } from "@/utils/util";
export default {
  name: "App",
  mounted() {
    this.init();
  },
  methods: {
    getQueryString(name) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      const r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    init() {
      const isWx = isInWX();
      if (!isWx) return;
      // 微信环境执行下面的逻辑
      const code = this.getQueryString("code");
      const sessionCode = sessionStorage.getItem("code");
      if (sessionCode) return;
      if (!code) {
        const baseUrl = window.location.href;
        // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb008219b38e1a8fa&redirect_uri=${baseUrl}&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect`;
        window.location.replace(
          `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf472028772bda02a&redirect_uri=${baseUrl}&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect`
        );
      } else {
        sessionStorage.setItem("code", code);
        getUserInfo({ code }).then((res) => {
          const { openid } = res;
          setStorage("openid", openid);
        });
        window.history.goBack();
      }
    },
  },
};
</script>

<style>
#app {
  font-family: PingFangSC-Medium, PingFang SC, Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}
</style>
