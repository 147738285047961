import m from "./md5";

export const md5 = (data) => {
  return m.hexMD5(data).toString();
};

export const setStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    console.log(error);
    return "";
  }
};

// 是否在微信内
export const isInWX = () => {
  let ua = navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == "micromessenger";
};
