import { Toast } from "vant";
const ruleMobile = (value) => {
  // 无类型时，只需验证是否为空
  return /^1[1-9](\d{1}\*{4}\d{4}|\d{9})$/.test(value);
};

export const validateMobile = (value) => {
  if (!value) {
    Toast.fail("请输入手机号");
    return false;
  }
  if (!ruleMobile(value)) {
    Toast.fail("请输入合法手机号");
    return false;
  }
  return true;
};
