<template>
  <van-button
    :class="[$style.codeBtn, small ? $style.small : '']"
    round
    :size="small ? 'small' : ''"
    block
    @click="sendCode"
    :color="!sending ? '#323232' : '#707070'"
  >
    {{ sendTxt }}</van-button
  >
</template>

<script>
import { send } from "@/api";
import { validateMobile } from "@/utils/validate";
export default {
  props: {
    mobile: String,
    small: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String, // 1-注册,2-重置密码,3-注销账号,4-实名认证
    },
    verify: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sendTxt: "获取验证码",
      sendTime: 60,
      sendInterval: null,
      sending: false, // 验证码是否正在哦发送
    };
  },
  methods: {
    sendCode() {
      if (this.sending) return;
      const validation = validateMobile(this.mobile);
      if (!validation) return;
      if (this.verify) {
        const verifyValues = Object.values(this.verify);
        let verifyResult = verifyValues.find(Boolean);
        if (!verifyResult) {
          this.$toast.fail("请先将信息填写完整");
          return;
        }
      }
      send({ mobile: this.mobile, type: this.type }).then(() => {
        this.sending = true;
        this.sendTime = 60;
        this.sendTxt = `${this.sendTime}秒`;
        this.sendInterval = setInterval(() => {
          if (this.sendTime <= 0) {
            this.sendTxt = "重新发送";
            this.sending = false;
            clearInterval(this.sendInterval);
          } else {
            this.sendTxt = `${--this.sendTime}秒`;
          }
        }, 1000);
      });
    },
  },
  destroyed() {
    clearInterval(this.sendInterval);
  },
};
</script>

<style lang="scss" module>
.codeBtn {
  width: 134px;
  background: #363636;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.5);
}
.small {
  width: 84px;
  font-size: 12px;
  padding: 0 10px;
}
</style>
