import Vue from "vue";
import {
  Toast,
  Dialog,
  Tab,
  Tabs,
  Image as VanImage,
  List,
  Search,
  Collapse,
  CollapseItem,
  Form,
  Field,
  Popup,
  Picker,
  Uploader,
  Button,
  Checkbox,
  Swipe,
  SwipeItem,
  Loading,
  Badge,
  Icon,
} from "vant";
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(VanImage);
Vue.use(List);
Vue.use(Search);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Form);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Uploader);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Loading);
Vue.use(Badge);
Vue.use(Icon);
Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;
