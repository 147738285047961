import Vue from "vue";
import VueRouter from "vue-router";
// import { getStorage, setStorage } from "../utils/util";
// import { share } from "@/api";
// import wx from "weixin-js-sdk";

Vue.use(VueRouter);
// 解决路由跳转当前的错误
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  scrollBehavior() {
    return { y: 0 };
  },
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Index",
      meta: {
        title: "ntf",
      },
      component: () => import(/* webpackChunkName: 'Index' */ "@/views/index"),
    },
    {
      path: "/optimization",
      name: "Optimization",
      meta: {
        title: "优选列表",
      },
      component: () =>
        import(/* webpackChunkName: 'Optimization' */ "@/views/optimization"),
    },
    {
      path: "/artist",
      name: "Artist",
      meta: {
        title: "艺术家列表",
      },
      component: () =>
        import(/* webpackChunkName: 'Artist' */ "@/views/artist"),
    },
    {
      path: "/artist/detail/:id",
      name: "ArtistDetail",
      meta: {
        title: "艺术家详情",
      },
      component: () =>
        import(/* webpackChunkName: 'ArtistDetail' */ "@/views/artist/detail"),
    },
    {
      path: "/spin",
      name: "Spin",
      meta: {
        title: "国家纺织信息中心",
      },
      component: () => import(/* webpackChunkName: 'Spin' */ "@/views/spin"),
    },
    {
      path: "/spin/detail/:id",
      name: "SpinDetail",
      meta: {
        title: "国家纺织信息详情",
      },
      component: () =>
        import(/* webpackChunkName: 'SpinDetail' */ "@/views/spin/detail"),
    },
    {
      path: "/cloth/detail/:id",
      name: "ClothDetail",
      meta: {
        title: "布料详情",
      },
      component: () =>
        import(/* webpackChunkName: 'ClothDetail' */ "@/views/cloth/detail"),
    },
    {
      path: "/pay",
      name: "Pay",
      meta: {
        title: "支付订单",
      },
      component: () => import(/* webpackChunkName: 'Pay' */ "@/views/pay"),
    },
    {
      path: "/pay/success",
      name: "PaySuccess",
      meta: {
        title: "支付成功",
      },
      component: () =>
        import(/* webpackChunkName: 'PaySuccess' */ "@/views/pay/success"),
    },
    {
      path: "/share",
      name: "Share",
      meta: {
        title: "转发炫耀",
      },
      component: () => import(/* webpackChunkName: 'Share' */ "@/views/share"),
    },
    {
      path: "/personal",
      name: "Personal",
      meta: {
        title: "我的主页",
      },
      component: () =>
        import(/* webpackChunkName: 'Personal' */ "@/views/personal"),
    },
    {
      path: "/order",
      name: "Order",
      meta: {
        title: "我的订单",
      },
      component: () => import(/* webpackChunkName: 'Order' */ "@/views/order"),
    },
    {
      path: "/give",
      name: "Give",
      meta: {
        title: "转赠列表",
      },
      component: () => import(/* webpackChunkName: 'Give' */ "@/views/give"),
    },
    {
      path: "/give/detail",
      name: "GiveDetail",
      meta: {
        title: "转赠详情",
      },
      component: () =>
        import(/* webpackChunkName: 'GiveDetail' */ "@/views/give/detail"),
    },
    {
      path: "/settings",
      name: "Settings",
      meta: {
        title: "设置",
      },
      component: () =>
        import(/* webpackChunkName: 'Settings' */ "@/views/settings"),
    },
    {
      path: "/settings/edit",
      name: "SettingsEdit",
      meta: {
        title: "修改个人资料",
      },
      component: () =>
        import(/* webpackChunkName: 'SettingsEdit' */ "@/views/settings/edit"),
    },
    {
      path: "/settings/privacy",
      name: "SettingsPrivacy",
      meta: {
        title: "隐私设置",
      },
      component: () =>
        import(
          /* webpackChunkName: 'SettingsPrivacy' */ "@/views/settings/privacy"
        ),
    },
    {
      path: "/settings/agreement",
      name: "SettingsAgreement",
      meta: {
        title: "用户协议",
      },
      component: () =>
        import(
          /* webpackChunkName: 'SettingsAgreement' */ "@/views/settings/agreement"
        ),
    },
    {
      path: "/personal/collect",
      name: "PersonalCollect",
      meta: {
        title: "我的藏品",
      },
      component: () =>
        import(
          /* webpackChunkName: 'PersonalCollect' */ "@/views/personal/collect"
        ),
    },
    {
      path: "/login",
      name: "Login",
      meta: {
        title: "登录",
      },
      component: () => import(/* webpackChunkName: 'Login' */ "@/views/login"),
    },
    {
      path: "/register",
      name: "Register",
      meta: {
        title: "注册",
      },
      component: () =>
        import(/* webpackChunkName: 'Register' */ "@/views/register"),
    },
    {
      path: "/forgetPwd",
      name: "ForgetPwd",
      meta: {
        title: "忘记密码",
      },
      component: () =>
        import(/* webpackChunkName: 'ForgetPwd' */ "@/views/forgetPwd"),
    },
  ],
});

/**
 * @description: 微信分享功能，不需要的可以删除
 * @return {*}
 */
// const initWx = () => {
//   const data = {
//     url: window.location.href,
//   };
//   share(data).then((res) => {
//     console.log(res);
//     let signature = res;
//     wx.config({
//       debug: signature.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
//       appId: signature.appId, // 必填，公众号的唯一标识
//       timestamp: signature.timestamp, // 必填，生成签名的时间戳<?= $data['timestamp']?>
//       nonceStr: signature.nonceStr, // 必填，生成签名的随机串<?= $data['noncestr']?>
//       signature: signature.signature, // 必填，签名<?= $data['signature']?>
//       jsApiList: signature.jsApiList, // 必填，需要使用的JS接口列表
//     });
//     wx.ready(function () {
//       wx.checkJsApi({
//         jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
//         success: function (res) {
//           console.log("====>", JSON.stringify(res));
//           // 以键值对的形式返回，可用的api值true，不可用为false
//           // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
//         },
//       });

//       const pyq = {
//         //分享到朋友圈
//         title: "芯卡会员系统", // 分享标题
//         desc: getStorage("desc"), // 分享描述
//         link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//         // 该链接是重定向链接，因为需要获取用户code，但是该链接又无法直接写微信获取code的链接，
//         // 所以需要点击后重新加载新的页面，来实现重定向，重新打开获取code的微信链接，实现获取用户信息的功能；
//         imgUrl: "https://m.xincardsh.com/logo.png", // 分享图标
//         fail: function (res) {
//           console.log(JSON.stringify(res));
//         },
//       };
//       const friend = {
//         //分享给好友
//         title: "芯卡会员系统", // 分享标题
//         desc: getStorage("desc"), // 分享描述
//         link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//         // 该链接是重定向链接，因为需要获取用户code，但是该链接又无法直接写微信获取code的链接，
//         // 所以需要点击后重新加载新的页面，来实现重定向，重新打开获取code的微信链接，实现获取用户信息的功能；
//         imgUrl: "https://m.xincardsh.com/logo.png", // 分享图标
//         fail: function (res) {
//           console.log(JSON.stringify(res));
//         },
//         success() {},
//       };
//       // 2.1 监听“分享给朋友”，按钮点击、自定义分享内容及分享结果接口
//       wx.updateAppMessageShareData(friend);
//       // 2.2 监听“分享到朋友圈”按钮点击、自定义分享内容及分享结果接口
//       wx.updateTimelineShareData(pyq);
//     });
//   });
// };
// router.beforeEach((to, from, next) => {
//   const { title, unLogin } = to.meta;
//   if (title) {
//     document.title = `${title}`;
//     setStorage("desc", title);
//   } else {
//     document.title = `芯卡`;
//     setStorage("desc", "芯卡会员系统");
//   }
//   // 需要登录的页面，并且没有token，则返回登录页面
//   if (!unLogin && !getStorage("token")) {
//     next({ name: "login", replace: true });
//   } else {
//     next();
//   }
// });
// router.afterEach(() => {
//   initWx();
// });
export default router;
