import { post } from "../service/http";

// 发送验证码
export const send = (data) => post("/sms/send", data);

// 注册
export const register = (data) => post("/user/register", data);

// 登录
export const login = (data) => post("/user/login", data);

// 用户实名认证
export const realVerified = (data) => post("/user/realVerified", data);

// 退出登录
export const logout = (data) => post("/user/logout", data);

// 重置密码
export const forgetPwd = (data) => post("/user/forgetPassword", data);

// 用户详情
export const fetchUserInfo = (data) => post("/user/info", data);

// 更新用户信息
export const updateUserInfo = (data) => post("/user/updateInfo", data);

// banner列表
export const fetchBannerList = (data) => post("/index/bannerList", data);

// 国家纺织中心面料列表
export const fetchDdcList = (data) => post("/index/ddcList", data);

// 优选推荐列表
export const fetchRecommendList = (data) => post("/index/recommendList", data);

// 艺术家列表
export const fetchArtistList = (data) => post("/index/artistList", data);

// 关注/取消关注艺术家
export const followArtist = (data) => post("/artist/follow", data);

// 艺术家详情
export const fetchArtistDetail = (data) => post("/artist/detail", data);

// 艺术家作品库
export const fetchArtistDdcList = (data) => post("/artist/ddcList", data);

// 作品详情
export const fetchArtistDdcDetail = (data) => post("/artist/ddcDetail", data);

// 面料详情
export const fetchDdcDetail = (data) => post("/ddc/detail", data);

// 上传图片
export const uploadToOss = (data) => post("/mobile/uploadToOss", data);

// 关注的艺术家列表
export const fetchFollowList = (data) => post("/user/followList", data);

// 下单
export const order = (data) => post("/order/order", data);

// 我的藏品
export const fetchCollectList = (data) => post("/user/collectList", data);

// 我的订单列表
export const fetchOrderList = (data) => post("/order/list", data);

// 取消订单
export const cancelOrder = (data) => post("/order/cancel", data);

// 转赠列表
export const fetchGiveList = (data) => post("/user/transferList", data);

// 确认/拒绝转赠（接受方的操作）
export const confirmTransfer = (data) => post("/user/confirmTransfer", data);

// 取消转赠（赠送方
export const cancelTransfer = (data) => post("/user/cancelTransfer", data);

// 确认接收人身份
export const fetchUser = (data) => post("/user/fetchUser", data);

// 转赠
export const transferDdc = (data) => post("/user/transferDdc", data);

// 微信中获取用户信息
export const getUserInfo = (data) => post("/user/getInfo", data);

// 注销接口
export const cancelAccount = (data) => post("/user/quit", data);
